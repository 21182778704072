const ROOT = {
  primaryTextColor: "#fff",
  tagDarkTextColor: "#202020",
  tagLightTextColor: "#fff",
  accentColor: "#FCCA40",
  featuredBannerColor: "#D02000",
  courseNoticeTextColor: "#e02020",
  navGradient: "linear-gradient(to bottom,#3f51b5,#2a3b97)",

  navTextColor: "#eee",
  navMenuLinkColor: "#c0c0c0",
  navMenuLinkColorHover: "#fff",
  codeTextColor: "#a9bcbc",
  priceBackgroundColor: "#FCCA40",
  ctaSecondaryColor: "#202020",
  ctaBackgroundColor: "#3f51b5",
  ctaHoverBackgroundColor: "#35459E",
}

const FONTS = {
  fonts: {
    main: "Muli, san-serif",
    code: "Roboto Mono, monospace",
    article: "Merriweather, Sans-serif",
  },
}

export const DARK = {
  colors: {
    primaryColor: "#7688ee",
    contentBackgroundColor: "#1a1a1a",
    searchBackgroundColor: "#000",
    contentTextColor: "#EBEBEB",
    headingTextColor: "#FFF",
    cardBackgroundColor: " #282828",
    cardHeaderBackgroundColor: "#222",
    itemListBorderColor: " #282828",
    metadataTextColor: "#bbb",
    blockquoteBackgroundColor: "#282828",
    codeBackgroundColor: " #282828",
    inlineCodeBackgroundColor: "#282828",
    newsletterFooterTextColor: "#ccc",
    footerTextColor: "#a0a0a0",
    buyNowCardBorderColor: "#404040",
    ctaSecondaryBackgroundColor: "#898C9F",
    ctaSecondaryHoverBackgroundColor: "#7B7F93",
    ...ROOT,
  },
  codeBorder: "1px solid #777",
  defaultShadowColor: "1px 1px 1px #222",
  ...FONTS,
}

export const LIGHT = {
  colors: {
    primaryColor: "#3f51b5",
    contentBackgroundColor: "#fff",
    searchBackgroundColor: "#fff",
    contentTextColor: "#000",
    headingTextColor: "#000",
    cardBackgroundColor: "#fafafa",
    cardHeaderBackgroundColor: "#f2f2f2",

    itemListBorderColor: "#e0e0e0",
    metadataTextColor: "#666",
    blockquoteBackgroundColor: "#efefef",
    codeBackgroundColor: "#222",
    inlineCodeBackgroundColor: "#e2e2e2",
    newsletterFooterTextColor: "#444",
    footerTextColor: "#757575",

    buyNowCardBorderColor: "#e0e0e0",
    ctaSecondaryBackgroundColor: "#DDDEE3",
    ctaSecondaryHoverBackgroundColor: "#D1D2D9",
    ...ROOT,
  },

  defaultShadowColor: "1px 1px 1px #c0c0c0",
  codeBorder: 0 as any,
  ...FONTS,
}
