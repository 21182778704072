import React, { Component } from "react"

import { ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import { LIGHT } from "../../theme"

const defaultThemeState = {
  dark: false,
  loadTheme: LIGHT,
  toggleTheme: () => {},
}

const ThemeContext = React.createContext(defaultThemeState)

const useTheme = () => React.useContext(ThemeContext)
class ThemeProvider extends Component {
  state = {
    dark: false,
  }
  toggleTheme = () => {
    let dark = !this.state.dark
    localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ dark })
  }
  render() {
    let loadTheme = LIGHT
    const { children } = this.props
    const { dark } = this.state
    return (
      <EmotionThemeProvider theme={loadTheme}>
        <ThemeContext.Provider
          value={{
            dark,
            loadTheme,
            toggleTheme: this.toggleTheme,
          }}
        >
          {children}
        </ThemeContext.Provider>
      </EmotionThemeProvider>
    )
  }
}
export { ThemeProvider, ThemeContext, useTheme }
